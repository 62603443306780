<template>
  <div>
    <maintenance-add-new
      :project_id="projectData.id"
      :is-add-new-maintenance-sidebar-active.sync="isAddNewMaintenanceSidebarActive"
      @refetch-data="refetchProjectMaintenances"
    />

    <task-add-new
      :project_id="projectData.id"
      :is-add-new-task-sidebar-active.sync="isAddNewTaskSidebarActive"
      @refetch-data="refetchProjectTasks"
    />

    <!-- First Row -->
    <b-row>
      <b-col
        cols="12"
        xl="5"
        lg="5"
        md="5"
      >
        <project-view-project-info-card />
      </b-col>

      <b-col
        cols="12"
        md="7"
        xl="7"
        lg="7"
      >
        <b-row class="match-height">
          <b-col
            cols="12"
            class="mb-1"
          >
            <!-- Actions -->
            <div
              v-if="projectData"
            >
              <b-row>
                <b-col lg="4">
                  <b-button
                    v-if="$can('create', 'tasks')"
                    block
                    variant="primary"
                    class="mb-1"
                    @click="isAddNewTaskSidebarActive = true"
                  >
                    <feather-icon
                      icon="PlusIcon"
                    />
                    <span class="align-middle">{{ $t('tasks.task') }}</span>
                  </b-button>
                </b-col>

                <b-col lg="4">
                  <b-button
                    v-if="$can('create', 'maintenances')"
                    block
                    variant="primary"
                    class="mb-1"
                    @click="isAddNewMaintenanceSidebarActive = true"
                  >
                    <feather-icon
                      icon="PlusIcon"
                    />
                    <span class="align-middle">{{ $t('maintenances.maintenance') }}</span>
                  </b-button>
                </b-col>

                <b-col lg="4">
                  <b-button
                    v-if="$can('create', 'issues')"
                    block
                    variant="primary"
                    class="mb-1"
                    disabled
                    @click="isAddNewIssueSidebarActive = true"
                  >
                    <feather-icon
                      icon="PlusIcon"
                    />
                    <span class="align-middle">{{ $t('issues.issue') }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>

          <b-col
            cols="12"
          >
            <project-view-project-job-card />
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
          >
            <project-view-project-additional-info-card />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-overlay
      :show="loading"
      variant="transparent"
      opacity="1"
      blur="5px"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      no-wrap
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BOverlay, BButton,
} from 'bootstrap-vue'
import {
 onBeforeMount, onUnmounted, ref,
} from "@vue/composition-api"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from "vue-toastification/composition"
import store from '@/store'
import router from '@/router'
import i18n from '@/libs/i18n'
import useProjectView from "./useProjectView"
import ProjectViewProjectInfoCard from './ProjectViewProjectInfoCard.vue'
import ProjectViewProjectJobCard from './ProjectViewProjectJobCard.vue'
import ProjectViewProjectAdditionalInfoCard from './ProjectViewProjectAdditionalInfoCard.vue'
import projectStoreModule from '../projectStoreModule'
import MaintenanceAddNew from '../../maintenance/maintenances-list/MaintenanceListAddNew.vue'
import TaskAddNew from '../../task/tasks-list/TaskListAddNew.vue'

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BButton,

    // Local Components
    ProjectViewProjectInfoCard,
    ProjectViewProjectJobCard,
    ProjectViewProjectAdditionalInfoCard,
    // ProjectViewProjectPermissionsCard,
    MaintenanceAddNew,
    TaskAddNew,
  },
  setup() {
    const TODO_APP_STORE_MODULE_NAME = 'project'

    // Register module
    if (!store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.registerModule(TODO_APP_STORE_MODULE_NAME, projectStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) {
        store.commit(`${TODO_APP_STORE_MODULE_NAME}/refreshState`, null)
        store.unregisterModule(TODO_APP_STORE_MODULE_NAME)
      }
    })

    const isAddNewMaintenanceSidebarActive = ref(false)
    const isAddNewTaskSidebarActive = ref(false)
    const isEditMaintenanceSidebarActive = ref(false)
    // const isAddNewIssueSidebarActive = ref(false)

    const toast = useToast()

    const loading = ref(true)
    const {
      projectData, fetchProject, fetchProjectActivity, fetchProjectTasks, fetchProjectMaintenances,
    } = useProjectView()

     onBeforeMount(async () => {
      loading.value = true
      await fetchProject({ id: router.currentRoute.params.id })
      if (!projectData.value) {
        // return back a listado, con ejemplo de toast y i18n en composition
        router.push({
          name: 'projects-list',
        }).then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${i18n.t('errors.Not found')} ${i18n.t('projects.title')}`,
              variant: 'danger',
              text: `${i18n.t('errors.Not found id')}`,
            },
          })
        })
      } else {
        fetchProjectActivity({ id: projectData.value.id })
      }
      loading.value = false
    })

    const refetchProjectTasks = () => {
      fetchProjectTasks({ id: projectData.value.id })
    }

    const refetchProjectMaintenances = () => {
      fetchProjectMaintenances({ id: projectData.value.id })
    }

    return {
      loading,
      projectData,

      isAddNewMaintenanceSidebarActive,
      isAddNewTaskSidebarActive,
      isEditMaintenanceSidebarActive,
      refetchProjectTasks,
      refetchProjectMaintenances,
    }
  },
}
</script>

<style>

</style>
