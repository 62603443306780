<template>
  <b-card>
    <b-tabs>
      <b-tab :title="$t('tasks.title')">
        <project-job-task-table :tasks-data="projectTasksData" />
      </b-tab>

      <b-tab :title="$t('maintenances.title')">
        <project-job-maintenance-table :maintenances-data="projectMaintenancesData" />
      </b-tab>

      <b-tab
        :title="$t('issues.title')"
        disabled
      />
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'
import { onMounted } from "@vue/composition-api"
import useProjectView from './useProjectView'
import ProjectJobMaintenanceTable from './project-job-content/ProjectJobMaintenanceTable.vue'
import ProjectJobTaskTable from './project-job-content/ProjectJobTaskTable.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    ProjectJobMaintenanceTable,
    ProjectJobTaskTable,
  },
  setup() {
    const {
     projectData, projectTasksData, fetchProjectTasks, projectMaintenancesData, fetchProjectMaintenances,
    } = useProjectView()

    onMounted(async () => {
      if (projectData.value) {
        fetchProjectTasks({ id: projectData.value.id })
        fetchProjectMaintenances({ id: projectData.value.id })
      }
    })

    return { projectTasksData, projectMaintenancesData }
  },
}
</script>

<style scoped>
.scroll-area {
  position: relative;
  margin: auto;
  height: 380px;
}
</style>
