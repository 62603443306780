<template>
  <div>
    <maintenance-list-edit
      :maintenance="maintenance"
      :is-maintenance-handler-sidebar-active.sync="isMaintenanceHandlerSidebarActive"
    />

    <b-row>
      <b-col
        cols="12"
        class="my-1"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              {{ $t("delete") }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          sticky-header="500px"
          :no-border-collapse="true"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <!-- Column: Name -->
          <template #cell(name)="data">
            <b-link
              class="font-weight-bold d-block text-nowrap"
              @click="handleMaintenanceClick(data.item)"
            >
              {{ data.item.name }}
            </b-link>
          </template>

          <template #cell(type)="row">
            <b-badge
              v-if="(row.item.type === 'Corrective')"
              class="bg-gradient-danger"
            >
              {{ $t('maintenances.' + (row.item.type)) }}
            </b-badge>
            <b-badge
              v-else
              class="bg-gradient-warning"
            >
              {{ $t('maintenances.' + (row.item.type || 'Preventive')) }}
            </b-badge>
          </template>

          <template #cell(users)="row">
            <users-group-simple
              v-if="row.item.users"
              :type="`maintenance_table_${row.item.id}`"
              :users-selected="row.item.users"
            />
          </template>

          <template #cell(tasks)="row">
            <b-button
              v-if="row.item.tasks.length"
              size="sm"
              variant="outline-primary"
              @click="row.toggleDetails"
            >
              {{ row.item.tasks.length }}
              <feather-icon
                :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                size="10"
              />
            </b-button>
          </template>

          <template #cell(expire)="data">
            {{ dateFormat(data.value) }}
          </template>
          <template #cell(done)="data">
            <b-avatar
              v-if="(data.value)"
              v-b-tooltip.hover.right="`${$t('Completed')}: ${dateFormat(data.value)}`"
              pill
              variant="light-success"
            >
              <feather-icon
                icon="CheckCircleIcon"
                size="14"
              />
            </b-avatar>
            <b-avatar
              v-else
              pill
              variant="warning"
            >
              <feather-icon
                icon="AlertTriangleIcon"
                size="14"
              />
            </b-avatar>
          </template>

          <template #row-details="row">
            <div class="todo-task-list-wrapper list-group scroll-area">
              <draggable
                v-model="row.item.tasks"
                :disabled="(row.item.done) ? true : false"
                handle=".draggable-task-handle"
                tag="ul"
                class="todo-task-list media-list"
                @change="orderMaintenanceTasks(row.item)"
              >
                <li
                  v-for="task in row.item.tasks"
                  :key="task.id"
                  class="todo-item"
                  :class="{ 'completed': task.done }"
                >
                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="draggable-task-handle d-inline"
                  />
                  <div class="todo-title-wrapper">
                    <div class="todo-title-area">
                      <div class="title-wrapper">
                        <b-form-checkbox
                          :disabled="(task.done || row.item.done) ? true : false"
                          :checked="(task.done) ? true : false"
                          @click.native.stop
                        />
                        <span class="todo-title">{{ task.name }}</span>
                      </div>
                    </div>
                    <div class="todo-item-action">
                      <div class="badge-wrapper mr-1">
                        <b-badge
                          pill
                          variant="light-primary"
                          class="text-capitalize"
                        >
                          {{ task.price }}
                        </b-badge>
                      </div>
                    </div>
                  </div>
                </li>
              </draggable>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BProgress, BLink, BCard, BCardText, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import draggable from 'vuedraggable'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from "vue-toastification/composition"
import store from "@/store"
import UsersGroupSimple from '@/views/apps/user/UsersGroupSimple.vue'
import MaintenanceListEdit from '@/views/apps/maintenance/maintenances-list/MaintenanceListEdit.vue'
import i18n from "@/libs/i18n"
import useGeneralView from "@/views/useGeneralView"

export default {
  components: {
    UsersGroupSimple,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BProgress,
    BLink,
    BCard,
    BCardText,
    BFormCheckbox,
    MaintenanceListEdit,
    draggable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    maintenancesData: {
      type: [Array, Object],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      perPage: 6,
      pageOptions: [6, 12],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
 key: 'id', label: 'Id', thClass: 'd-none', tdClass: 'd-none',
},
        { key: 'done', label: i18n.t('maintenances.done'), sortable: true },
        { key: 'type', label: i18n.t('maintenances.type'), sortable: true },
        { key: 'name', label: i18n.t('maintenances.denomination'), sortable: true },
        { key: 'tasks', label: i18n.t('maintenances.tasks') },
        { key: 'users', label: i18n.t('team') },
        { key: 'expire', label: i18n.t('maintenances.expire_date') },
        { key: 'renew', label: i18n.t('maintenances.renew_period'), sortable: true },
        { key: 'price', label: i18n.t('maintenances.price'), sortable: true },
      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
        {
          1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
        }],
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({ text: f.label, value: f.key }))
    },
    items() {
      // Create an options list from our fields
      return this.maintenancesData
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async orderMaintenanceTasks(row) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      const arrayOrder = []
      Object.entries(row.tasks).forEach(([key, val]) => {
        arrayOrder.push(val.id)
      })

      await store.dispatch('model/maintenance/updateMaintenanceTasksOrder', { id: row.id, tasks: arrayOrder })
          .then(response => true,
              // Order in store the tasks in the maintenance
              // store.commit('maintenance/updateTasksData', response.data)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: i18n.t('message.maintenance updated'),
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              }))
          .catch(error => {
            if (error.response.status === 404) {
              //
            }
          })
      /*
      // Composition API mode
        await updateMaintenanceTasksOrder({ id: row.id, tasks: arrayOrder })
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: i18n.t('message.maintenance updated'),
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })
            })
       */
    },
  },
  setup() {
    const maintenance = ref(null)
    const isMaintenanceHandlerSidebarActive = ref(false)

    const { dateFormat } = useGeneralView()

    const handleMaintenanceClick = maintenanceData => {
      maintenance.value = maintenanceData
      isMaintenanceHandlerSidebarActive.value = true
    }

    return {
      maintenance,
      isMaintenanceHandlerSidebarActive,
      handleMaintenanceClick,
      dateFormat,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '.~@core/scss/base/components/include'; // Components includes
tr.b-table-details > td{
  padding: 0 !important;
}
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
.todo-task-list-wrapper {
  position: relative;
  height: auto !important; // calc(100% - 3.56rem); // ? search box height (3.49rem) + 1px bottom border till 2 decimals
  background-color: transparent;
  border-radius: 0;
  .todo-task-list {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      cursor: pointer;
      transition: all 0.2s, border-color 0s;
      position: relative;
      padding: 0.893rem 2rem;
      &:not(:first-child) {
        border-top: 1px solid $border-color;
      }
      &:hover {
        transform: translateY(-4px);
        box-shadow: 0 3px 10px 0 $border-color;
        transition: all 0.2s;
      }
      .todo-title-wrapper {
        display: flex;
        justify-content: space-between;
      }
      .todo-title-area,
      .title-wrapper {
        display: flex;
        align-items: center;
      }
      // Todo Item Action
      .todo-item-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        a {
          cursor: pointer;
          font-size: 1.2rem;
          line-height: 1.5;
        }
      }

      .badge-wrapper {
        display: flex;
        .badge:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }
  }
  // When we search, no-results
  .no-results {
    display: none;
    padding: 1.5rem;
    text-align: center;
    &.show {
      display: block;
    }
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
