<template>
  <div>
    <vue-perfect-scrollbar
      ref="activity"
      :settings="perfectScrollbarSettings"
      class="scroll-area"
    >
      <app-timeline>
        <transition-group
          tag="div"
          enter-active-class="animated fadeInLeft"
          leave-active-class="animated fadeOutLeft"
        >
          <app-timeline-item
            v-for="activity in projectActivityData"
            :key="activity.id"
          >
            <div>
              <h6>{{ activity.action }}</h6>
              <small class="text-muted">
                {{ activity.date }} / {{ activity.done_by }}
              </small>
            </div>
          </app-timeline-item>
        </transition-group>
      </app-timeline>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BCard, BImg, BMedia, BAvatar,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { onBeforeMount } from "@vue/composition-api"
import router from '@/router'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useProjectView from '../useProjectView'

export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
    VuePerfectScrollbar,
  },
  setup() {
    const { projectActivityData } = useProjectView()

    const perfectScrollbarSettings = {
          maxScrollbarLength: 150,
          wheelPropagation: false,
        }

    return { projectActivityData, perfectScrollbarSettings }
  },
}
</script>

<style>
.scroll-area {
  position: relative;
  margin: auto;
  height: 300px;
}
.list-item {
  display: inline-block;
  width: 100%;
}
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
