<template>
  <b-overlay
    :show="loading"
    variant="transparent"
    opacity="1"
    blur="5px"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <div v-if="projectDataForm">
      <b-card>
        <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25 mb-2">
          <h4 class="mb-0">
            {{ projectData.name }} <br>
            <small>{{ projectData.reference_code }} - {{ $t("clients.client") }}: {{ projectDataForm.client.name }}<br></small>
          </h4>
          <project-users-group
            v-if="projectData.id"
            :project-data="projectData"
          />
        </b-card-header>

        <b-form
          @submit.prevent="onSubmit"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group
                :label="$t('projects.responsible')"
                label-for="responsible"
              >
                <v-select
                  v-model="projectResponsibleDataForm"
                  :options="users"
                  :label="$t('name')"
                  class="assignee-selector"
                  input-id="responsible"
                >

                  <template #option="{ avatar, name }">
                    <b-avatar
                      size="26"
                      :src="avatar"
                    />
                    <span class="ml-50 d-inline-block align-middle"> {{ name }}</span>
                  </template>

                  <template #selected-option="{ avatar, name }">
                    <b-avatar
                      size="26"
                      :src="avatar"
                      :text="name"
                    />

                    <span class="ml-50 d-inline-block align-middle"> {{ name }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field:  project name -->
            <b-col
              cols="12"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  :label="$t('projects.project')"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="projectDataForm.name"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: reference_code -->
            <b-col
              cols="12"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="reference_code"
                rules="required"
              >
                <b-form-group
                  :label="$t('projects.reference_code')"
                  label-for="reference_code"
                >
                  <b-form-input
                    id="reference_code"
                    v-model="projectDataForm.reference_code"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                :label="$t('projects.installation_type')"
                label-for="installation_type"
              >
                <b-form-input
                  id="installation_type"
                  v-model="projectDataForm.installation_type"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                :label="$t('projects.power')"
                label-for="power"
              >
                <b-form-input
                  id="power"
                  v-model="projectDataForm.power"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="12"
            >
              <hr>
            </b-col>

            <!-- Fields: project address -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                :label="$t('projects.address')"
                label-for="address1"
              >
                <b-form-input
                  id="address1"
                  v-model="projectDataForm.address1"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label=""
                label-for="address2"
              >
                <b-form-input
                  id="address2"
                  v-model="projectDataForm.address2"
                />
              </b-form-group></b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('users.city')"
                label-for="city"
              >
                <b-form-input
                  id="city"
                  v-model="projectDataForm.city"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('users.state')"
                label-for="state"
              >
                <b-form-input
                  id="state"
                  v-model="projectDataForm.state"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('users.postal_code')"
                label-for="postal_code"
              >
                <b-form-input
                  id="postal_code"
                  v-model="projectDataForm.postal_code"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                :label="$t('comments')"
                label-for="comments"
              >
                <b-form-textarea
                  id="comments"
                  v-model="projectDataForm.comments"
                  :placeholder="$t('comments')"
                  rows="3"
                  max-rows="8"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="12"
            >
              <hr>
            </b-col>

            <!-- Field: expected_start_date -->
            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group
                :label="$t('projects.expected_start_date')"
                label-for="expected_start_date"
              >
                <b-input-group>
                  <b-form-datepicker
                    v-model="projectDataForm.expected_start_date"
                    :initial-date="projectDataForm.expected_start_date"
                    aria-placeholder="DD/MM/YYY"
                    left
                    calendar-width="100%"
                    locale="es-ES"
                    start-weekday="1"
                    placeholder=""
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- Field: real_start_date -->
            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group
                :label="$t('projects.real_start_date')"
                label-for="real_start_date"
              >
                <b-input-group>
                  <b-form-datepicker
                    v-model="projectDataForm.real_start_date"
                    :initial-date="projectDataForm.real_start_date"
                    aria-placeholder="DD/MM/YYY"
                    left
                    calendar-width="100%"
                    locale="es-ES"
                    start-weekday="1"
                    placeholder=""
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- Field: expected_finish_date -->
            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group
                :label="$t('projects.expected_finish_date')"
                label-for="expected_finish_date"
              >
                <b-input-group>
                  <b-form-datepicker
                    v-model="projectDataForm.expected_finish_date"
                    :initial-date="projectDataForm.expected_finish_date"
                    aria-placeholder="DD/MM/YYY"
                    left
                    calendar-width="100%"
                    locale="es-ES"
                    start-weekday="1"
                    placeholder=""
                    :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- Field: real_finish_date -->
            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group
                :label="$t('projects.real_finish_date')"
                label-for="real_finish_date"
              >
                <b-input-group>
                  <b-form-datepicker
                    v-model="projectDataForm.real_finish_date"
                    :initial-date="projectDataForm.real_finish_date"
                    aria-placeholder="DD/MM/YYY"
                    left
                    calendar-width="100%"
                    locale="es-ES"
                    start-weekday="1"
                    placeholder=""
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  />
                </b-input-group>
              </b-form-group>

            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <!-- Action Buttons -->
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1 btn-block"
                type="submit"
                :disabled="loading"
              >
                <b-spinner
                  v-if="loading"
                  small
                  type="grow"
                />
                {{ this.$t('save_changes') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from "vue-toastification/composition"
import {
  BButton, BMedia, BAvatar, BRow, BCol,
  BFormGroup, BFormInput, BForm, BTable,
  BInputGroup, BInputGroupPrepend, BInputGroupAppend,
  BCard, BCardHeader, BCardTitle, BFormCheckbox, BBadge,
  BOverlay, BFormTextarea, BFormDatepicker, BSpinner,
} from 'bootstrap-vue'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
 ref, onBeforeMount, watch, computed, onMounted,
} from "@vue/composition-api"
import i18n from '@/libs/i18n'
import router from '@/router'
import store from '@/store'
import useUsersList from "@/views/apps/user/users-list/useUsersList"
import useProjectView from './useProjectView'
import projectUsersGroup from './projectUsersGroup'
// import projectResponsible from './projectResponsible'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroup,
    BOverlay,
    BFormTextarea,
    BFormDatepicker,
    BSpinner,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    projectUsersGroup,
  },
  setup() {
    const loading = ref(false)
    const toast = useToast()

    const {
      projectData, updateProject,
      projectResponsibleData, fetchProjectResponsible,
    } = useProjectView()

    const {
      users, getUsers,
    } = useUsersList()

    const projectDataForm = computed(() => projectData.value)
    const projectResponsibleDataForm = ref(projectResponsibleData.value)

    const {
      getValidationState,
    } = formValidation(projectData)

    watch(projectData, value => {
      if (projectData.value) {
        fetchProjectResponsible({ id: projectData.value.id })
        loading.value = false
      }
    })

    onMounted(() => {
      if (projectData.value) {
        getUsers()
        fetchProjectResponsible({ id: projectData.value.id })
        .then(() => {
          projectResponsibleDataForm.value = projectResponsibleData.value
        })

        loading.value = false
      }
    })

    const onSubmit = async () => {
      loading.value = true
      const formData = {
        ...projectDataForm.value,
        responsible_id: projectResponsibleDataForm.value.id,
        client_id: projectData.value.client.id,
      }
      delete formData.client
      delete formData.responsible

      await updateProject(formData)
          .then(() => {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: i18n.t('message.save register'),
                variant: 'success',
              },
            })
          })
          .catch(error => {
          })
      loading.value = false
    }

    return {
      projectResponsibleData,
      projectResponsibleDataForm,
      users,
      projectData,
      projectDataForm,
      onSubmit,
      loading,
      getValidationState,
    }
  },
}

</script>

<style>

</style>
