<template>
  <div>
    <b-form
      @submit.prevent="onSubmit"
    >
      <b-row>
        <b-col md="9">
          <b-form-file
            v-model="photosToUpload"
            :placeholder="$t('Choose a file or drop it here')"
            :drop-placeholder="$t('Drop file here')"
            multiple
          />
        </b-col>
        <!-- Action Buttons -->
        <b-col md="3">
          <b-button
            variant="primary"
            class="mb-sm-0 mr-0 mr-sm-1"
            block
            type="submit"
            :disabled="loading"
          >
            <b-spinner
              v-if="loading"
              small
              type="grow"
            />
            {{ this.$t('upload') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <vue-perfect-scrollbar
      ref="activity"
      :settings="perfectScrollbarSettings"
      class="scroll-area"
    >
      <b-container fluid>
        <transition-group
          tag="div"
          class="row"
          enter-active-class="animated fadeInLeft"
          leave-active-class="animated fadeOutLeft"
        >
          <b-col
            v-for="photo in projectPhotosData"
            :key="photo.id"
            cols="12"
            sm="3"
            md="2"
          >
            <enlargeable-image
              :src="photo.image"
              :src_large="photo.image"
              animation_duration="200"
            >
              <feather-icon
                icon="XCircleIcon"
                class="cart-item-remove cursor-pointer"
                @click.stop="onDeletePhoto(photo.id)"
              />
              <b-img
                class="mb-1 mb-sm-0"
                left
                fluid
                :src="photo.image"
              />
            </enlargeable-image>
          </b-col>
        </transition-group>
      </b-container>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BCard, BLink, BImg, BMedia, BAvatar, BFormFile, BCol, BRow, BButton, BForm, BSpinner, BContainer,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { ref, onMounted } from "@vue/composition-api"
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ToastificationContent from "@core/components/toastification/ToastificationContent"
import { useToast } from "vue-toastification/composition"
import EnlargeableImage from '@diracleo/vue-enlargeable-image'
import router from '@/router'
import useProjectView from "@/views/apps/project/projects-view/useProjectView"
import i18n from "@/libs/i18n"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    BLink,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
    BFormFile,
    BButton,
    BForm,
    BSpinner,
    BContainer,
    EnlargeableImage,
    VuePerfectScrollbar,
  },
  setup() {
    const loading = ref(false)
    const toast = useToast()

    const photosToUpload = ref(null)

    const {
      projectData,
      fetchProjectPhotos, projectPhotosData,
        uploadPhotos, deletePhoto,
    } = useProjectView()

    const perfectScrollbarSettings = {
          maxScrollbarLength: 150,
          wheelPropagation: false,
        }

    const onDeletePhoto = async photoId => {
      loading.value = true
      await deletePhoto({ id: projectData.value.id, idPhoto: photoId })
          .then(() => {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: i18n.t('message.photo deleted'),
                variant: 'success',
              },
            })
          })
          .catch(error => {
          })
      loading.value = false
    }
    const onSubmit = async () => {
      loading.value = true
      await uploadPhotos({ id: projectData.value.id, images: photosToUpload.value })
          .then(() => {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: i18n.t('message.photos uploaded'),
                variant: 'success',
              },
            })
          })
          .catch(error => {
          })
      loading.value = false
    }

    onMounted(async () => {
      await fetchProjectPhotos({ id: projectData.value.id })
    })

    return {
      onDeletePhoto, loading, onSubmit, photosToUpload, projectPhotosData, perfectScrollbarSettings,
    }
  },
}
</script>

<style lang="scss">
.scroll-area {
  position: relative;
  margin: auto;
  height: 300px;
}

/* your passed-in element */
.enlargeable-image > .slot {
  display:inline-block;
  max-width:100%;
  max-height:100%;
  cursor:zoom-in;
}
/* default img if no element passed in */
.enlargeable-image > .slot > img.default {
  max-width:100%;
  vertical-align:middle;
}
/* passed-in element when growth is happening */
.enlargeable-image.active > .slot {
  opacity:0.3;
  filter:grayscale(100%);
}
/* full version that grows (background image allows seamless transition from thumbnail to full) */
.enlargeable-image .full {
  cursor:zoom-out;
  background-color: transparent;
  align-items:center;
  justify-content:center;
  background-position: center center;
  background-repeat:no-repeat;
  background-size:contain;
  display:none;
}
.enlargeable-image .full > img {
  object-fit:contain;
  width:100%;
  height:100%;
}
/* full version while getting bigger */
.enlargeable-image .full.enlarging {
  display:flex;
  position:fixed;
  left:0px;
  top:0px;
  width:100%;
  height:100%;
  background-color: transparent;
  cursor:zoom-out;
  z-index:3;
}
/* full version while at its peak size */
.enlargeable-image .full.enlarged {
  display:flex;
  position:fixed;
  left:0px;
  top:0px;
  width:100%;
  height:100%;
  background-color: rgba(255, 255, 255, 0.9) !important;
  cursor:zoom-out;
  z-index:2;
}
/* full version while getting smaller */
.enlargeable-image .full.delarging {
  display:flex;
  position:fixed;
  left:0px;
  top:0px;
  width:100%;
  height:100%;
  background-color: transparent;
  cursor:zoom-in;
  z-index:1;
}
</style>
