<template>
  <div id="my-container">
    <users-group
        type="Project"
      :users-selected.sync="projectUsersData"
      :users-selected-array.sync="usersProjectSelected"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BSkeleton,
  BSkeletonWrapper,
  BSpinner,
} from 'bootstrap-vue'
import {
 reactive, ref, computed, onMounted, watch,
} from "@vue/composition-api"
import Ripple from 'vue-ripple-directive'
import _ from 'lodash'
import useUsersList from "@/views/apps/user/users-list/useUsersList"
import router from '@/router'
import store from '@/store'
import useProjectView from "./useProjectView"
import UsersGroup from '@/views/apps/user/UsersGroup.vue'
import useGeneralView from "@/views/useGeneralView";

export default {
  components: {
    BRow,
    BCol,
    BSkeleton,
    BSkeletonWrapper,
    BSpinner,
    UsersGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    projectData: {
      type: [Array, Object],
      required: false,
      default: null,
    },
  },
  setup(props) {
    const propProjectData = (props.projectData && props.projectData !== undefined) ? computed(() => props.projectData) : ''

    const usersProjectSelected = ref(null)
    const loading = ref(false)

    const {
      projectUsersData, fetchProjectUsers, addUserProject, fetchProjectMaintenances, fetchProjectTasks, fetchProjectActivity
    } = useProjectView()
    const { fetchNotifications } = useGeneralView()

    watch(usersProjectSelected, async (currentValue, oldValue) => {
        await onOk()
    })

    const buildComponent = async () => {
      loading.value = true
      await fetchProjectUsers({ id: propProjectData.value.id })
      loading.value = false
    }

    const onOk = async () => {
      loading.value = true
      await addUserProject({ id: propProjectData.value.id, users: usersProjectSelected.value })
      .then(async response => {
        await buildComponent()
        fetchNotifications()
        fetchProjectMaintenances({ id: propProjectData.value.id })
        fetchProjectTasks({ id: propProjectData.value.id })
        fetchProjectActivity({ id: propProjectData.value.id })
      })
      loading.value = false
    }

    onMounted(async () => {
      if (propProjectData.value) {
        buildComponent()
      }
    })
    return {
      propProjectData,
      projectUsersData,
      usersProjectSelected,
      addUserProject,
      loading,
    }
  },
}
</script>
<style lang="scss" scoped>
</style>
