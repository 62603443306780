<template>
  <div>
    <task-list-edit
      :task="task"
      :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
    />
    <b-row>
      <b-col
        cols="12"
        class="my-1"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            :placeholder="$t('Type to Search')"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              {{ $t('Clear') }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          sticky-header="500px"
          :no-border-collapse="true"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(avatar)="data">
            <b-avatar :src="data.value" />
          </template>
          <!-- Column: Name -->
          <template #cell(name)="data">
            <b-link
              class="font-weight-bold d-block text-nowrap"
              @click="handleTaskClick(data.item)"
            >
              {{ data.item.name }}
            </b-link>
          </template>
          <template #cell(progress)="data">
            <div class="progress-wrapper">
              <b-card-text class="mb-0">
                {{ $t('progress') }} {{ data.value }}%
              </b-card-text>
              <b-progress
                v-model="data.value"
                max="100"
              />
            </div>
          </template>
          <template #cell(expected_start_date)="data">
            {{ dateFormat(data.value) }}
          </template>
          <template #cell(started_at)="data">
            {{ dateFormat(data.value) }}
          </template>
          <template #cell(finished_at)="data">
            {{ dateFormat(data.value) }}
          </template>
          <template #cell(users)="row">
            <users-group-simple
              v-if="row.item.users"
              :type="`task_table_${row.item.id}`"
              :users-selected="row.item.users"
            />
          </template>
          <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ data.value }}
            </b-badge>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BProgress, BLink, BCardText,
} from 'bootstrap-vue'
import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import UsersGroupSimple from "@/views/apps/user/UsersGroupSimple"
import TaskListEdit from "@/views/apps/task/tasks-list/TaskListEdit"
import i18n from "@/libs/i18n"
import useGeneralView from "@/views/useGeneralView"

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BProgress,
    BLink,
    BCardText,
    UsersGroupSimple,
    TaskListEdit,
  },
  props: {
    tasksData: {
      type: [Array, Object],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      perPage: 6,
      pageOptions: [6, 12],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'id', label: 'Id', thClass: 'd-none', tdClass: 'd-none' },
        { key: 'name', label: i18n.t('tasks.task'), sortable: true },
        { key: 'active', label: i18n.t('tasks.active') },
        { key: 'progress', label: i18n.t('tasks.progress'), sortable: true },
        { key: 'users', label: i18n.t('team') },
        { key: 'status', label: i18n.t('tasks.status'), sortable: true },
      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    items() {
      // Create an options list from our fields
      return this.tasksData
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  setup() {
    const task = ref(null)
    const isTaskHandlerSidebarActive = ref(false)

    const { dateFormat } = useGeneralView()

    const handleTaskClick = taskData => {
      task.value = taskData
      isTaskHandlerSidebarActive.value = true
    }

    return {
      task,
      isTaskHandlerSidebarActive,
      handleTaskClick,
      dateFormat,
    }
  },
}
</script>
