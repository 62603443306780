<template>
  <b-card>
    <b-tabs>
      <b-tab
        :title="$t('activity')"
      >
        <project-view-project-activity />
      </b-tab>
      <b-tab
        :title="$t('photos')"
      >
        <project-view-project-images />
      </b-tab>

      <b-tab
        :title="$t('Documentation')"
      >
        <project-view-project-docs />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'
import ProjectViewProjectActivity from './project-additional-info/ProjectViewProjectActivity.vue'
import ProjectViewProjectImages from './project-additional-info/ProjectViewProjectImages.vue'
import ProjectViewProjectDocs from './project-additional-info/ProjectViewProjectDocs.vue'

export default {
  components: {
    ProjectViewProjectActivity,
    ProjectViewProjectImages,
    ProjectViewProjectDocs,
    BTabs,
    BTab,
    BCard,
  },
  setup() {
  },
}
</script>

<style scoped>

</style>
