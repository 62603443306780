export default {
  namespaced: true,
  state: {
    data: null,
    responsible: null,
    users: null,
    activity: null,
    maintenances: null,
    tasks: null,
    issues: null,
    photos: null,
    documents: null,
    materials: null,
  },
  getters: {
    getData(state) {
      return state.data
    },
    getUsers(state) {
      return state.users
    },
    getActivity(state) {
      return state.activity
    },
    getMaintenances(state) {
      return state.maintenances
    },
    getTasks(state) {
      return state.tasks
    },
    getIssues(state) {
      return state.issues
    },
    getPhotos(state) {
      return state.photos
    },
    getDocuments(state) {
      return state.documents
    },
  },
  mutations: {
    refreshState(state, data) {
      state.data = data
      state.responsible = data
      state.users = data
      state.activity = data
      state.maintenances = data
      state.tasks = data
      state.issues = data
      state.photos = data
      state.documents = data
    },
    setData(state, data) {
      state.data = data
    },
    updateData(state, data) {
      Object.entries(data).forEach(([key, val]) => { state.data[key] = val })
    },
    setResponsible(state, data) {
      state.responsible = data
    },
    setUsers(state, data) {
      state.users = data
    },
    setActivity(state, data) {
      state.activity = data
    },
    setMaintenances(state, data) {
      state.maintenances = data
    },
    setTasks(state, data) {
      state.tasks = data
    },
    setIssues(state, data) {
      state.issues = data
    },
    setPhotos(state, data) {
      state.photos = data
    },
    setDocuments(state, data) {
      state.documents = data
    },
    setMaterials(state, data) {
      state.materials = data
    },
  },
  actions: {
    refreshAll({ commit }) {
      commit('setData', { data: null })
      commit('setResponsible', { data: null })
      commit('setUsers', { data: null })
      commit('setActivity', { data: null })
      commit('setMaintenances', { data: null })
      commit('setTasks', { data: null })
      commit('setIssues', { data: null })
      commit('setPhotos', { data: null })
      commit('setDocuments', { data: null })
    },
  },
}
